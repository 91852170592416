import { Container, Box } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const Wrap = styled.div`
  max-width: 100vw;
  overflow: hidden;
`

export const StyledContainer = styled(Container)<{ $isChartExpanded: boolean }>`
  position: relative;
  max-width: ${({ $isChartExpanded }) => ($isChartExpanded ? '100%' : '1440px')};
`

export const StyledContent = styled.div<{ $isChartExpanded: boolean; $isChartDisplayed: boolean }>`
  column-gap: 24px;
  padding-right: ${({ $isChartExpanded }) => ($isChartExpanded ? '24px' : '0')};
  display: grid;
  align-items: start;

  ${({ $isChartDisplayed, theme }) =>
    $isChartDisplayed &&
    `
      grid-template-columns: auto 498px;
    
      ${theme.mediaQueries.bdXxl} {
        grid-template-columns: auto 452px;
      }
    
      ${theme.mediaQueries.bdXl} {
        grid-template-columns: auto 512px;
      }
    
      ${theme.mediaQueries.bdLg} {
        grid-template-columns: none;
      }
    `}
`

export const DesktopChartWrap = styled.div`
  width: calc(100% - 8px);
`

export const StyledInputCurrencyWrapper = styled(Box)<{ $isChartExpanded: boolean }>`
  position: relative;
  min-width: 498px;
  padding-top: ${({ $isChartExpanded }) => ($isChartExpanded ? '24px' : '0')};

  ${({ theme }) => theme.mediaQueries.bdXxl} {
    min-width: 452px;
  }

  ${({ theme }) => theme.mediaQueries.bdXl} {
    min-width: 512px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    margin: 0 auto;
  }

  ${({ theme }) => theme.mediaQueries.bdMd} {
    min-width: 100%;
    width: 100%;
  }
`
