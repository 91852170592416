import { useTranslation } from '@pancakeswap/localization'
import { Currency, CurrencyAmount, Percent, TradeType } from '@pancakeswap/sdk'
import { LegacyPair as Pair } from '@pancakeswap/smart-router/legacy-router'
import { QuestionHelper, Text, AutoColumn } from '@pancakeswap/uikit'
import { formatAmount } from '@pancakeswap/utils/formatFractions'
import { memo } from 'react'
import styled from 'styled-components'

import { RowBetween, RowFixed } from 'components/Layout/Row'
import { Field } from 'state/swap/actions'
import FormattedPriceImpact from './FormattedPriceImpact'

const TextStyled = styled(Text)`
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 1.28;
`
const SpanStyled = styled.span`
  color: ${({ theme }) => theme.colors.gray200};
`

export const TradeSummary = memo(function TradeSummary({
  inputAmount,
  outputAmount,
  tradeType,
  slippageAdjustedAmounts,
  priceImpactWithoutFee,
  realizedLPFee,
  isMM = false,
}: {
  hasStablePair?: boolean
  inputAmount?: CurrencyAmount<Currency>
  outputAmount?: CurrencyAmount<Currency>
  tradeType?: TradeType
  slippageAdjustedAmounts: {
    INPUT?: CurrencyAmount<Currency>
    OUTPUT?: CurrencyAmount<Currency>
  }
  priceImpactWithoutFee?: Percent
  realizedLPFee?: CurrencyAmount<Currency>
  isMM?: boolean
}) {
  const { t } = useTranslation()
  const isExactIn = tradeType === TradeType.EXACT_INPUT

  return (
    <AutoColumn gap="8px">
      <RowBetween>
        <RowFixed>
          <TextStyled color="gray200" letterSpacing="0.5px">
            {isExactIn ? t('Minimum received') : t('Maximum sold')}
          </TextStyled>
          <QuestionHelper
            size="16px"
            text={t(
              'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
            )}
            ml="4px"
            placement="top"
          />
        </RowFixed>
        <RowFixed>
          <TextStyled color="gray100" medium>
            {isExactIn ? (
              <>
                {formatAmount(slippageAdjustedAmounts?.[Field.OUTPUT], 4) ?? '-'}{' '}
                <SpanStyled>{outputAmount?.currency?.symbol}</SpanStyled>
              </>
            ) : (
              <>
                {formatAmount(slippageAdjustedAmounts?.[Field.INPUT], 4) ?? '-'}{' '}
                <SpanStyled>{inputAmount?.currency?.symbol}</SpanStyled>
              </>
            )}
          </TextStyled>
        </RowFixed>
      </RowBetween>
      {priceImpactWithoutFee && (
        <RowBetween style={{ padding: '4px 0 0 0' }}>
          <RowFixed>
            <TextStyled color="gray200">{t('Price Impact')}</TextStyled>
            <QuestionHelper
              size="16px"
              text={
                <>
                  <TextStyled>
                    <TextStyled medium display="inline-block">
                      {t('AMM')}
                    </TextStyled>
                    {`: ${t('The difference between the market price and estimated price due to trade size.')}`}
                  </TextStyled>
                  <TextStyled mt="10px">
                    <TextStyled medium display="inline-block">
                      {t('MM')}
                    </TextStyled>
                    {`: ${t('No slippage against quote from market maker')}`}
                  </TextStyled>
                </>
              }
              ml="4px"
              placement="top"
            />
          </RowFixed>

          {isMM ? (
            <Text color="gray200" letterSpacing="0.5px">
              --
            </Text>
          ) : (
            <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
          )}
        </RowBetween>
      )}

      {realizedLPFee && (
        <RowBetween style={{ padding: '4px 0 0 0' }}>
          <RowFixed>
            <TextStyled color="gray200">{t('Trading Fee')}</TextStyled>
            <QuestionHelper
              size="16px"
              text={
                <>
                  <TextStyled mb="12px">
                    <TextStyled medium display="inline-block">
                      {t('AMM')}
                    </TextStyled>
                    : {t('Fee ranging from 0.1% to 0.01% depending on the pool fee tier.')}
                  </TextStyled>
                  <TextStyled mt="10px">
                    <TextStyled medium display="inline-block">
                      {t('MM')}
                    </TextStyled>
                    :{' '}
                    {t(
                      'XCAD DEX does not charge any fees for trades. However, the market makers charge an implied fee of 0.05% - 0.25% (non-stablecoin) / 0.01% (stablecoin) factored into the quotes provided by them.',
                    )}
                  </TextStyled>
                </>
              }
              ml="4px"
              placement="top"
            />
          </RowFixed>
          <TextStyled color="gray100" medium>
            {`${formatAmount(realizedLPFee, 4)} `}
            <SpanStyled color="gray200">{inputAmount?.currency?.symbol}</SpanStyled>
          </TextStyled>
        </RowBetween>
      )}
    </AutoColumn>
  )
})
